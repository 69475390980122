import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "nectars-brand-voice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#nectars-brand-voice",
        "aria-label": "nectars brand voice permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Nectar's brand voice`}</h2>
    <p>{`Nectar are the savvy friend who’s always ready to point you in the right direction – whether that’s the heads up on a good deal, something you need in a hurry, or the next big thing. Nectar should sound:`}</p>
    <h3>{`Down to earth`}</h3>
    <p>{`Nectar are friendly and informal, using the most widely understood terms.`}</p>
    <h3>{`In the know`}</h3>
    <p>{`Nectar are there to lend a hand with anything: from T&Cs, to using a new QR code. But they make sure never to patronise or verge on irritating.`}</p>
    <h3>{`A bit cheeky`}</h3>
    <p>{`Nectar are full of life, so every now and again they’ll try and tempt you into having fun or doing something different.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "how-to-sound-down-to-earth",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-sound-down-to-earth",
        "aria-label": "how to sound down to earth permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`How to sound down to earth`}</h2>
    <p>{`Use everyday language that feels warm instead of sounding cold and robotic.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">Pop in your card number and we’ll do the rest</Do>
  <Dont mdxType="Dont">Please enter your Nectar card details, then press 'continue'</Dont>
    </Guideline>
    <p>{`It’s okay to use little colloquialisms that aren’t proper words, as long as you're not trying too hard.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">Ooh look at all those Nectar points</Do>
  <Dont mdxType="Dont">Oioi! You’re in the money now</Dont>
    </Guideline>
    <p>{`Use inclusive pronouns that help Nectar feel friendlier. They don’t want to sound distant and formal.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <strong>Argos has joined the family</strong>
    We’re excited to announce our newest partner, Argos.
  </Do>
  <Dont mdxType="Dont">
    <strong>A new partnership with Argos</strong>
    Nectar is proud to announce Argos as its newest partner.
  </Dont>
    </Guideline>
    <hr></hr>
    <h2 {...{
      "id": "how-to-sound-in-the-know",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-sound-in-the-know",
        "aria-label": "how to sound in the know permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`How to sound in the know`}</h2>
    <p>{`Make people feel like Nectar are giving them a helpful heads up, but don’t be patronising or condescending.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <div>
      <strong>Grab every Nectar-filled opportunity</strong>
      Here’s a quick refresh of all the brands you can collect and spend with.
    </div>
    <div>
      <strong>You’re good to go</strong>
      Looks like you’ve got enough points for £5 off your next shop.
    </div>
  </Do>
  <Dont mdxType="Dont">
    <div>
      <strong>Did you know?</strong>
      Only 10% of our customers are using Nectar to its full potential. Are you one
      of them?
    </div>
    <div>
      <strong>Your discount is waiting</strong>
      Why haven’t you used your £5 off yet?
    </div>
  </Dont>
    </Guideline>
    <p>{`Simplify wherever possible to make things clear and helpful. Don’t overcomplicate things by using long-winded sentences.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    Scan your digital card in-store by opening the app and tapping ‘Card’. (Your
    plastic card’s still fine to use if you’d prefer.)
  </Do>
  <Dont mdxType="Dont">
    Use the New Nectar app in-store to collect points with your digital card, or
    continue to use your existing card when prompted at the till.
  </Dont>
    </Guideline>
    <hr></hr>
    <h2 {...{
      "id": "how-to-sound-a-bit-cheeky",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-sound-a-bit-cheeky",
        "aria-label": "how to sound a bit cheeky permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`How to sound a bit cheeky`}</h2>
    <p>{`Add a touch of playfulness every now and again, but don’t be too weird or sarcastic.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <div>
      <strong>+100 points on any toilet tissue</strong>
      Don’t say we don’t treat you.
    </div>
    <div>It’s a bit like getting something for nothing.</div>
  </Do>
  <Dont mdxType="Dont">
    <div>
      <strong>There, that wasn’t so hard</strong>
      +100 points coming your way.
    </div>
    <div>Find Nectar points in places you’d never dream of.</div>
  </Dont>
    </Guideline>
    <p>{`Don’t sound cynical or use slang that not everyone will understand.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">Do your thing. Get more Nectar points.</Do>
  <Dont mdxType="Dont">
    60 points for stuff you were buying anyway. It’s amazing everyone doesn’t do
    this.
  </Dont>
    </Guideline>
    <hr></hr>
    <h2 {...{
      "id": "nectars-terminology",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#nectars-terminology",
        "aria-label": "nectars terminology permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Nectar's terminology`}</h2>
    <h3>{`Nectar`}</h3>
    <p>{`Nectar always use a capital N when writing ‘Nectar’ in text. That’s the same if they’re talking about ‘Nectar points’ or introducing ‘the new Nectar’ or referencing the ‘Nectar’ brand in press releases.`}</p>
    <h3>{`Spending Nectar points`}</h3>
    <p>{`When talking about someone using their Nectar points, use the word ‘spend’ or one of these interchangeable terms:`}</p>
    <ul>
      <li parentName="ul">{`Swap`}</li>
      <li parentName="ul">{`Turn`}</li>
      <li parentName="ul">{`Splash`}</li>
      <li parentName="ul">{`Splurge`}</li>
    </ul>
    <h3>{`Collecting Nectar points`}</h3>
    <p>{`When talking about someone collecting Nectar points, don't use terms like 'earn' as they make collecting Nectar points seems like hard work. Instead, use the word ‘collect’ or one of these interchangeable terms:`}</p>
    <ul>
      <li parentName="ul">{`Get`}</li>
      <li parentName="ul">{`Grab`}</li>
      <li parentName="ul">{`Top up`}</li>
      <li parentName="ul">{`Swipe`}</li>
      <li parentName="ul">{`Fill up`}</li>
      <li parentName="ul">{`Bag`}</li>
      <li parentName="ul">{`Win`}</li>
    </ul>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  Our copy team are on hand to answers questions or help with your next project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      